/* eslint-disable no-undef, camelcase, no-console */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Wrapper, SectionContainer, SearchButton } from './styles';

//import mockData from '../../../mockData.json';

import { saveSearchAmigoInfo, searchAmigos, clearSearchAmigoInfo} from '../../redux/actions';
import Autocomplete from "../Autocomplete";
import {withTranslation} from 'react-i18next';

class SearchAmigoForm extends Component {
  constructor() {
    super();
    this.state = {
      lattitude: 0,
      longitude: 0
    }
  }

  componentDidMount() {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getPosition, this.errorGettingPosition);
    }
    const {clearAmigoInfo} = this.props;
    clearAmigoInfo({});
  }

  errorGettingPosition = () => {
    //toast.error(error.message);
  };

  getPosition = (position) => {
    this.setState({lattitude: position.coords.lattitude, longitude: position.coords.longitude});
  };

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if(charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };

  onBlur = (e) => {
    const { saveAmigoInfo } = this.props;
    saveAmigoInfo(e.target.name, e.target.value);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { searchUser, checked } = this.props;
    searchUser(1, checked);
  };

  changeValue = (name, value) => {
    const { saveAmigoInfo } = this.props;
    saveAmigoInfo(name, value);
  };

  renderDropDown = (data, id, name) => {
    const { searchAmigo } = this.props;
    const options = data.map((opt) => ({ label: opt, value: opt }));
    return(
      <Autocomplete defaultValue={searchAmigo ? searchAmigo[name] ? searchAmigo[name] : '' : ''} options={options} assignedState={name} changeHandler={this.changeValue}/>
    );
  };

  render() {
    const { searchAmigo,t } = this.props;

    const interest = [
      "Kayaking","Kite Boarding","Paddle Boarding","Snorkling","Surfing","Swimming","Wakeboarding","Water Polo","Water Skiing","Wind Surfing","Baseball","Basketball","Bowling","Cricket","Dodgeball","Field Hockey","Football","Golf","Handball","Kickball","Lacrosse","Racquetball","Rugby","Soccer","Softball","Squash","Table Tennis","Tennis","Volleyball","BMX","Cycling","Motor Biking","Mountain Biking","Roller Skating","Skateboarding","Hockey","Ice Skating","Skiing","Sledding","Snowboarding","Boxing","Fencing","Kickboxing","Martial Arts","Wrestling","CrossFit","Olympic Lifting","Strength Training","Acrobatics","Aerial Arts","Aerobics","Ballet","Calisthenics","Dance","Gymnastics","Hiking","Obstacle Racing","Parkour","Pilates","Rock Climbing","Running","Trail Running","Triathlon","Walking","Yoga","Archery","Badminton","Camping","Cooking","Frisbee","Gardening","Horseback Riding","Jumping Rope","KettleBells","Meditation","Track and Field"
    ];
    
    const searchForm = [
      [
        {"label": t("First Name"), "type": "text", "name": "firstName", "maxLength": 30},
        {"label": t("Last Name"), "type": "text", "name": "lastName", "maxLength": 30}
      ],
      [
        {"label": t("Areas of Interest"), "type": "dropdown", "name": "interest" }
      ]
    ];

    return (
      <div>
        <Wrapper onSubmit={(e) => this.onSubmit(e)}>
          {
            searchForm.map((fields, index) => (
              <SectionContainer key={index} innerSection={index === 2} dropDown={fields[0].type === 'dropdown'}>
                {
                  fields.map((field) => (
                    <div key={field.name}>
                      <span>{field.label}:</span>
                      {
                        field.type === 'dropdown' ?
                          this.renderDropDown(interest.sort(), `${field.name}-dropdown`, field.name) :
                          <input
                            type={field.type}
                            name={field.name}
                            onChange={(e) => this.onBlur(e)}
                            onKeyPress={(e) => field.type ==='number' && this.isNumberKey(e)}
                            min={18}
                            max={100}
                            value={searchAmigo ? searchAmigo[field.name] ? searchAmigo[field.name] : '' : ''}
                            maxLength={field.maxLength}
                          />
                      }
                    </div>
                  ))
                }
              </SectionContainer>
            ))
          }
          <SectionContainer>
            <SearchButton type="submit">{t("search")}</SearchButton>
          </SectionContainer>
        </Wrapper>
      </div>
    )
  }
}

SearchAmigoForm.propTypes = {
  saveAmigoInfo: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
  clearAmigoInfo: PropTypes.func.isRequired,
  searchAmigo: PropTypes.object,
  checked: PropTypes.bool,
  checkFunction: PropTypes.func.isRequired,
  t: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  saveAmigoInfo: (name, value) => dispatch(saveSearchAmigoInfo(name, value)),
  clearAmigoInfo: () => dispatch(clearSearchAmigoInfo()),
  searchUser: (pageNumber, companyCheck) => dispatch(searchAmigos(pageNumber, companyCheck))
});

const mapStateToProps = (state) => ({
  searchAmigo: state.profileData.searchAmigo
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchAmigoForm));
