import styled from 'styled-components';
const MediumFont = 'Rubik-Regular';
const UserName = styled.p`
  // font-size: 15px;
  // letter-spacing: 1px;
  // color: #282C37;
    text-transform: capitalize;
  // margin-bottom: 5px;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // display: -webkit-box;
  // text-overflow: ellipsis;
  // word-break: break-all;
  // padding: 0 10px !important;
  // font-family: ${MediumFont}
  // font-family: Rubik;
  // font-style: normal;
  // font-weight: 500;
  // font-size: 18px;
  // line-height: 16px;
  // padding: 17px;

  font-family: 'Rubik-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  color: #0D4270;
  padding-left: 15px;
  // padding-top: 15px;
  text-align: left;

  .section1 {
    font-family: 'Rubik-Medium';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 16px;
   color: #0D4270; 
  //  padding-top: 10px;
   //padding-right: 20px;
   position: relative;
   //right: 23px;
   text-align: left;
  }

  .section2 {
    min-height: 35px;
    font-family: Rubik;
    font-style: normal;
   font-weight: normal;
   font-size: 16px;
   text-align: center;
   color: #9C9C9C;
   padding-top: 10px;
   padding-bottom: 10px;
   position: relative;
   //right: 36px;
   text-align: left;
  }
`;
const EmptyButton = styled.button`
 background-color: white;
 background-size: 100%;
`;

const EditProfile = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  cursor: pointer;
  
  > span {
    color: rgb(153,153,153);
    padding-left: 5px;
  }
  > i {
    font-size: 10px;
    color: rgb(153,153,153);
  }
`;

const AoiBar = styled.div`
  float: left;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  //height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  .text_class {
    //width: 50%;
    > p{
      ont-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #0D4270;
      mix-blend-mode: normal;
    }
  }
  // > div {
  //   width: 40px;
  //   height: 40px;
  //   // margin-right: 4px;
  //   display: inline-block;
  //   background: linear-gradient(
  //     360deg,rgb(30,100,129) 0%,rgb(13,66,92) 60.94%,rgb(2,44,67) 100%);
  //   border-radius: 6px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   > img {
  //     width: 25px;
  //     height: 25px;
  //     border-radius: 6px;
  //     object-fit: contain;
  //   }
  // }
`;

const ImgDiv = styled.div`
width: 40px;
height: 40px;
// margin-right: 4px;
display: inline-block;
background: linear-gradient(
  360deg,rgb(30,100,129) 0%,rgb(13,66,92) 60.94%,rgb(2,44,67) 100%);
border-radius: 6px;
display: flex;
justify-content: center;
align-items: center;
margin-left: ${({margin}) => margin ? '0px' : '12.5px'};
// cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};

> img {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  object-fit: contain;
}
`;

const StarContainer = styled.div`
  float: left;
  width: 100%;
  padding-bottom: 25px;
  text-align: center;
`;

const StarIcon = styled.i`
  font-size: 15px;
  color: ${(props) => props.completed ? 'orange' : 'gray'};
  padding: 0 2px;
`;

const LevelText = styled.p`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  float: left;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
`;

export { AoiBar, StarContainer, StarIcon, UserName, EditProfile, LevelText,EmptyButton, ImgDiv };
