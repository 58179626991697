import styled, { css } from 'styled-components';
//const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';
//const RegularFont = 'Rubik-Light';

const SidebarGrid = styled.div`
  width: 200px;
  float: left;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  background-color: ${(props) => props.showCompany ? '#3d4d67': 'white'};
  text-align: center;
  
  @media(max-width: 950px) {
    display: block;
    width: 100%;
    position: relative;
  }
`;

const UserName = styled.p`
  font-size: 15px;
  letter-spacing: 1px;
  color: #282C37;
  text-transform: capitalize;
  margin: 6px 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-all;
  padding: 0 10px !important;
  font-family: ${MediumFont}
`;

const EditProfile = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  cursor: pointer;
  
  > span {
    color: rgb(153,153,153);
    padding-left: 5px;
  }
  > i {
    font-size: 10px;
    color: rgb(153,153,153);
  }
`;

const ImageAndProfile = styled.div`
  float: left;
  width: 100%;
  margin-top: 25px;
  text-align: center;
  & > p {
    padding: 0;
    text-align: center;
    color: #5f5f5f;
  }
  & > div {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & > div > .edit-profile-pic {
    background: rgba(0,0,0,0.7);
    transition: height ease 0.5s;
    position: absolute;
    bottom: 0;
    height: 0;
    width: inherit;
    color: orange;
    cursor: pointer;
    left: 0;
    margin-top: 0;
  }
  & > div > .edit-profile-pic > i {
    margin-top: 11%;
    color: orange;
    font-size: 15px;
  }
  & > div:hover > .edit-profile-pic {
    height: 40%;
  }
`;

const AoiBar = styled.div`
  float: left;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  //height: 50px;
  padding: 10px 0
  text-align: center;
  display: flex;
  justify-content: center;
  > div {
    width: 35px;
    height: 35px;
    margin-right: 4px;
    display: inline-block;
    background-color: #F6F6F8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: contain;
    }
  }
`;

const Navigation = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  background-color: white;
  border-top: ${(commonDashboard) => commonDashboard ? '1px solid #d2d2d2' : '0px'};
  > li:first-child {
    margin-top: ${(props) => props.showCompany ? '0px' : '5px'};
    border-top: ${(props) => props.showCompany ? '2px solid #d2d2d2' : '0px'};
  }
  
  > li {
    color: white;
    height: 60px;
    display: table; 
    width: 100%;
    float: left;
    position: relative;
  }
  
  @media(max-width: 950px) {
    background-color: white;
    padding: 0;
  }
`;

const StyledSvg = styled.svg`
  height: ${({height}) => height ? height : '30px'};
  width: ${({width}) => width ? width : '30px'};
  float: left;
  fill: ${({ showCompany }) => showCompany ? 'transparent' : '#999999'};
  padding: ${({ padding }) => padding ? padding : '2px 0px 0px'};
  margin: ${({marginTop}) => marginTop && marginTop };
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'}
  pointer-events: none;
`;

const StyledLink = styled.a`
  font-size: 12px;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  padding-left: 10%;
  padding-top: 10px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.showCompany ? '#3d4d67': 'white'};
  .companyDashboardLabel {
     padding-left: 15px !important;
  }  
   .iconLinkContainer {
    display: flex;
    align-items: center;
    >span {
      padding-left: 20px;
    }
  }
  
  > span {
    color: ${(props) => props.showCompany ? 'white': ''};
  }

  >span svg {
    fill: ${({ showCompany }) => showCompany ? 'white' : ''};
  }

  > div {
    height: 35px;
    width: 5px;
    float: right;
  }

  &:hover, &:active, &:focus {
    color: ${({commonDashboard, colour}) => commonDashboard ? colour: 'white'};
    background-color: ${(props, colour) => props.showCompany ? colour: 'white'};
    font-family: Open-Sans-Bold;
    outline: none;
    text-decoration: none;
    
    > span svg {
      fill: ${({commonDashboard, colour}) => commonDashboard ? colour : 'white'};
    }

    > div {
      background-color: ${({colour}) => colour};
      width: 5px;
      height: 35px;
    }
  
    > i {
      display: block;
      color: white;
    }
  }
  
  ${({ isProfileLinks }) => isProfileLinks && css`
    padding-top: 0;
    > img {
      float: left;
      margin-right: 20px;
    }
  `}
  
  ${({ active }) => active && css`
    font-family: ${({commonDashboard}) => commonDashboard ? 'Open-Sans-Bold' : 'normal'};
    color: ${({colour}) => colour}
    outline: none;
    text-decoration: none;
  
    >span svg {
      fill: ${({commonDashboard, colour}) => commonDashboard ? colour : 'white'};
    }
  
    > div {
      background-color: ${({colour}) => colour};
    }

    > i {
      display: block;
      color: white;
    }
  `}
  
  > span {
    float: left;
  }
  
  @media (max-width: 950px) {
    padding-left: 30px;
  }
`;

const HamBurgerContainer = styled.div`
  padding: 3px 10px;
  float: left;
  font-size: 23px;
  display: inline-block;
  position: relative;
  margin: 10px;
  margin-left: 20px;
  
  @media (min-width: 951px) {
    display: none;
  }
  
  > i {
    cursor: pointer;
    color: ${(props) => props.showCompany ? 'rgb(62, 127, 159)': '#3e7f9f'};
  }
`;

const MainSideBarLinks = styled.div`
  float: left;
  width: 100%;
  
  @media (max-width: 950px) {
    display: none;
  }
  
  ${({ smallDevices }) => smallDevices && css`
    display: none;
    
    @media (max-width: 950px) {
      display: block;
    }
  `}
`;

const SideActiveColor = styled.div`
  height: 100%;
  width: 8px;
  background-color: ${(props) => props.active ? '#159fc9': '#3d4d67'};
  position: absolute;
  top:0px;
  left:0px;
  bottom:0px;
  z-index: 1;
`;

export { SidebarGrid, ImageAndProfile, AoiBar, Navigation, StyledSvg, UserName, EditProfile, StyledLink,
  HamBurgerContainer, MainSideBarLinks, SideActiveColor };
