import styled, { css } from 'styled-components';

import { CustomCheckbox } from '../CreateEvent/styles';

const BoldFont = 'Rubik-Bold';

const Wrapper = styled.form`
  width: 100%;
  padding: 40px 50px 88px 40px;
  background-color: white;
  float: left;

  @media (max-width: 500px) {
    padding: 30px 10px 58px;
  }
`;

const StyledCheckBox = styled(CustomCheckbox)`
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  margin: 0;
`;

const SectionContainer = styled.div`
  width: 100%;
  margin-top: 45px;
  margin-bottom: 10px;
  float: left;

  .dropdown.btn-group {
    width: 100%;
    .dropdown-menu {
      width: 100%;
      height: 300px;
      overflow-y: auto;
    }
  }
  .searchable-dropdown {
    margin-top: 15px;
    
  }
  .menu-item__control {
    height: 46px;
    background-color: #fff;
    border: 1px solid #cdcbcb;
    cursor: pointer;
  }

  > div {
    width: ${({dropDown}) => dropDown ? '100%' : '50%'};
    padding-left: 25px;
    float: left;
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      padding-left: 0;
      padding-right: ${({dropDown}) => dropDown ? '0px' : '25px'};

      @media (max-width: 500px) {
        padding-right: 0;
      }
    }

    @media (max-width: 500px) {
      width: 100%;
      padding-left: 0;
      margin-top: 10px;
    }

    > span {
      display: inline-block;
      width: 100%;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgb(51,51,51);
      font-family: ${BoldFont};
    }

    > input {
      width: 100%;
      height: 46px;
      border-radius: 4px;
      background-color: #ffffff;
      border: 1px solid #cdcbcb;
      margin-top: 15px;
      padding: 0 10px;
      
      &:hover {
        outline: none;
        border-color: hsl(0,0%,70%);
      }

      &:focus, &:active {
        outline: none;
      }
    }
  }

  ${({ innerSection }) => innerSection && css`
    width: 50%;
    padding-right: 0 !important;
    > div {
      padding-left: 15px;
      &:first-child {
        padding-left: 0;
        padding-right: 20px;

        @media (max-width: 500px) {
          padding-right: 0;
        }
      }
      @media (max-width: 500px) {
        padding-left: 0;
      }
    }
    @media (max-width: 1050px) and (min-width: 950px) {
      width: 100% !important;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  `}

  @media (max-width: 500px) {
    margin-top: 10px;
    margin-bottom: 0;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SearchButton = styled.button`
  text-transform: uppercase;
  padding: 0 56px;
  height: 35px;
  border-radius: 17px;
  background-color: #ef6727;
  border: none;
  color: white;
  font-family: ${BoldFont};
  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(255,255,255);

  &:active, &:focus {
    outline: none;
  }

  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

export { Wrapper, StyledCheckBox, SectionContainer, SearchButton };
